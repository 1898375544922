import { default as adminsmtyREAEs5SMeta } from "/tmp/build_f1b4aa1a/pages/administration/admins.vue?macro=true";
import { default as _91id_93EAYH6lKOboMeta } from "/tmp/build_f1b4aa1a/pages/administration/billing/[id].vue?macro=true";
import { default as indexrWk4tBZNEnMeta } from "/tmp/build_f1b4aa1a/pages/administration/billing/index.vue?macro=true";
import { default as bookingsXb7mLczF91Meta } from "/tmp/build_f1b4aa1a/pages/administration/bookings.vue?macro=true";
import { default as exportsiZLnwUcYwXMeta } from "/tmp/build_f1b4aa1a/pages/administration/exports.vue?macro=true";
import { default as fidelitykpMOTrV1BqMeta } from "/tmp/build_f1b4aa1a/pages/administration/fidelity.vue?macro=true";
import { default as invoicesNbbBKzW61cMeta } from "/tmp/build_f1b4aa1a/pages/administration/invoices.vue?macro=true";
import { default as policy3bgwSzB4vnMeta } from "/tmp/build_f1b4aa1a/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93EsuSddnTAwMeta } from "/tmp/build_f1b4aa1a/pages/administration/payment/[id].vue?macro=true";
import { default as index1F9a7njogjMeta } from "/tmp/build_f1b4aa1a/pages/administration/payment/index.vue?macro=true";
import { default as statisticsR0sT0x9HxQMeta } from "/tmp/build_f1b4aa1a/pages/administration/statistics.vue?macro=true";
import { default as index56hRiA7TX1Meta } from "/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationIpi7SK8DWGMeta } from "/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexyxYF6spz9HMeta } from "/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifyt4RY9EToTYMeta } from "/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsPIEYYi6GDuMeta } from "/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93R4UWJNp1dGMeta } from "/tmp/build_f1b4aa1a/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexDQDWXqu9NyMeta } from "/tmp/build_f1b4aa1a/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labels5FJ0qNf7hLMeta } from "/tmp/build_f1b4aa1a/pages/administration/trip-labels.vue?macro=true";
import { default as administrationOAWwHVchycMeta } from "/tmp/build_f1b4aa1a/pages/administration.vue?macro=true";
import { default as swileP7iHm3z6qfMeta } from "/tmp/build_f1b4aa1a/pages/auth/swile.vue?macro=true";
import { default as indexlwD7wYkbBIMeta } from "/tmp/build_f1b4aa1a/pages/bookings/index.vue?macro=true";
import { default as indexlDNxDwehXwMeta } from "/tmp/build_f1b4aa1a/pages/bookings/validator/index.vue?macro=true";
import { default as pending8Miz0q9YdZMeta } from "/tmp/build_f1b4aa1a/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93g0VYujCXUeMeta } from "/tmp/build_f1b4aa1a/pages/car/[id].vue?macro=true";
import { default as exchangeto90XUcDwgMeta } from "/tmp/build_f1b4aa1a/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresMf1F8erfOPMeta } from "/tmp/build_f1b4aa1a/pages/flight/[id]/fares.vue?macro=true";
import { default as indexeT9HlaAe7aMeta } from "/tmp/build_f1b4aa1a/pages/flight/[id]/index.vue?macro=true";
import { default as forgotTuKMNXLtDYMeta } from "/tmp/build_f1b4aa1a/pages/forgot.vue?macro=true";
import { default as get_45swile_45appGC4gK8xrEwMeta } from "/tmp/build_f1b4aa1a/pages/get-swile-app.vue?macro=true";
import { default as helpd2reNSAOWbMeta } from "/tmp/build_f1b4aa1a/pages/help.vue?macro=true";
import { default as _91id_93Kk8sy8ZxcKMeta } from "/tmp/build_f1b4aa1a/pages/hotel/[id].vue?macro=true";
import { default as _91id_93ZLS69qZVjnMeta } from "/tmp/build_f1b4aa1a/pages/impersonate/[id].vue?macro=true";
import { default as indexKi2OFpfqSgMeta } from "/tmp/build_f1b4aa1a/pages/index.vue?macro=true";
import { default as _91suffix_93t9wpconA0VMeta } from "/tmp/build_f1b4aa1a/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_939LSmmPKElsMeta } from "/tmp/build_f1b4aa1a/pages/invite/[token].vue?macro=true";
import { default as _91id_93VmXmDMohC7Meta } from "/tmp/build_f1b4aa1a/pages/journeys/[id].vue?macro=true";
import { default as indexAjSA7bzvCdMeta } from "/tmp/build_f1b4aa1a/pages/journeys/index.vue?macro=true";
import { default as membersyHZthALQcvMeta } from "/tmp/build_f1b4aa1a/pages/myteam/members.vue?macro=true";
import { default as policyQuxgIGXjfVMeta } from "/tmp/build_f1b4aa1a/pages/myteam/policy.vue?macro=true";
import { default as validatorskIBT47o2slMeta } from "/tmp/build_f1b4aa1a/pages/myteam/validators.vue?macro=true";
import { default as myteamp8rGt75TtMMeta } from "/tmp/build_f1b4aa1a/pages/myteam.vue?macro=true";
import { default as new_45userj1coC8gbizMeta } from "/tmp/build_f1b4aa1a/pages/new-user.vue?macro=true";
import { default as passwordXX3a0AeOMgMeta } from "/tmp/build_f1b4aa1a/pages/password.vue?macro=true";
import { default as indexlZAPEePjHhMeta } from "/tmp/build_f1b4aa1a/pages/search/car/[search_id]/index.vue?macro=true";
import { default as index8EFIuxTfS2Meta } from "/tmp/build_f1b4aa1a/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93ahonZTQ5OZMeta } from "/tmp/build_f1b4aa1a/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93ckquX69Lj6Meta } from "/tmp/build_f1b4aa1a/pages/search/flight/[search_id].vue?macro=true";
import { default as indexOW6i8CIQISMeta } from "/tmp/build_f1b4aa1a/pages/search/flight/index.vue?macro=true";
import { default as indexkgwqYXkVH3Meta } from "/tmp/build_f1b4aa1a/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93q42TTH80vzMeta } from "/tmp/build_f1b4aa1a/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexCVvJVcisGdMeta } from "/tmp/build_f1b4aa1a/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93W6O32couiuMeta } from "/tmp/build_f1b4aa1a/pages/search/train/[search_id].vue?macro=true";
import { default as indexf4UfYriwh3Meta } from "/tmp/build_f1b4aa1a/pages/search/train/index.vue?macro=true";
import { default as search8pGyIbMBO7Meta } from "/tmp/build_f1b4aa1a/pages/search.vue?macro=true";
import { default as indexX4yg9tjJJ6Meta } from "/tmp/build_f1b4aa1a/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationIYRXyjj2qZMeta } from "/tmp/build_f1b4aa1a/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyJAfgTMMAsrMeta } from "/tmp/build_f1b4aa1a/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsN0RHD8FI8FMeta } from "/tmp/build_f1b4aa1a/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93TnrK1wcjicMeta } from "/tmp/build_f1b4aa1a/pages/teams/[teamId].vue?macro=true";
import { default as indexWM1tRoZCJJMeta } from "/tmp/build_f1b4aa1a/pages/teams/index.vue?macro=true";
import { default as teams7VpGyvTGjRMeta } from "/tmp/build_f1b4aa1a/pages/teams.vue?macro=true";
import { default as indexqRcH9lrc4SMeta } from "/tmp/build_f1b4aa1a/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexDqFSQe16eZMeta } from "/tmp/build_f1b4aa1a/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexfXkb95iumPMeta } from "/tmp/build_f1b4aa1a/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexsuq7HKOJB5Meta } from "/tmp/build_f1b4aa1a/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexrbnF47eSrCMeta } from "/tmp/build_f1b4aa1a/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licenseoVY1klohKJMeta } from "/tmp/build_f1b4aa1a/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelitynsEsEaRFaHMeta } from "/tmp/build_f1b4aa1a/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexXjd09NR1vZMeta } from "/tmp/build_f1b4aa1a/pages/users/[id]/index.vue?macro=true";
import { default as passportokOu1zELfMMeta } from "/tmp/build_f1b4aa1a/pages/users/[id]/passport.vue?macro=true";
import { default as passwordxRZFC6zeZOMeta } from "/tmp/build_f1b4aa1a/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93bsX0rST8wDMeta } from "/tmp/build_f1b4aa1a/pages/users/[id].vue?macro=true";
import { default as indexUd2OEm2wlsMeta } from "/tmp/build_f1b4aa1a/pages/users/index.vue?macro=true";
import { default as vouchersX4X4YzPg6CMeta } from "/tmp/build_f1b4aa1a/pages/vouchers.vue?macro=true";
import { default as component_45stubFTH6VTpzgQMeta } from "/tmp/build_f1b4aa1a/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFTH6VTpzgQ } from "/tmp/build_f1b4aa1a/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationOAWwHVchycMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/admins.vue").then(m => m.default || m)
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/billing/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/bookings.vue").then(m => m.default || m)
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/exports.vue").then(m => m.default || m)
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/organization/policy.vue").then(m => m.default || m)
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93R4UWJNp1dGMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: index56hRiA7TX1Meta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifyt4RY9EToTYMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/policy/modify.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_f1b4aa1a/pages/administration/trip-labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileP7iHm3z6qfMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/auth/swile.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_f1b4aa1a/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexlDNxDwehXwMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/bookings/validator/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pending8Miz0q9YdZMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/bookings/validator/pending.vue").then(m => m.default || m)
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/car/[id].vue").then(m => m.default || m)
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_f1b4aa1a/pages/flight/[id]/exchange.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_f1b4aa1a/pages/flight/[id]/fares.vue").then(m => m.default || m)
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/flight/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotTuKMNXLtDYMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appGC4gK8xrEwMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/get-swile-app.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_f1b4aa1a/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/impersonate/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexKi2OFpfqSgMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93t9wpconA0VMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/invitation/[suffix].vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_939LSmmPKElsMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/journeys/[id].vue").then(m => m.default || m)
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_f1b4aa1a/pages/journeys/index.vue").then(m => m.default || m)
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_f1b4aa1a/pages/myteam.vue").then(m => m.default || m),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_f1b4aa1a/pages/myteam/members.vue").then(m => m.default || m)
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_f1b4aa1a/pages/myteam/policy.vue").then(m => m.default || m)
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_f1b4aa1a/pages/myteam/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45userj1coC8gbizMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/new-user.vue").then(m => m.default || m)
  },
  {
    name: "password",
    path: "/password",
    meta: passwordXX3a0AeOMgMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_f1b4aa1a/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/car/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/car/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/car/init/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/flight/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/flight/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/hotel/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/hotel/[search_id]/rooms/[hotelIds].vue").then(m => m.default || m)
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/train/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_f1b4aa1a/pages/search/train/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teams7VpGyvTGjRMeta?.name,
    path: "/teams",
    meta: teams7VpGyvTGjRMeta || {},
    component: () => import("/tmp/build_f1b4aa1a/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: _91teamId_93TnrK1wcjicMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_f1b4aa1a/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_f1b4aa1a/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_f1b4aa1a/pages/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_f1b4aa1a/pages/teams/[teamId]/policy.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_f1b4aa1a/pages/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_f1b4aa1a/pages/teams/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_f1b4aa1a/pages/train/[id]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_f1b4aa1a/pages/train/[id]/exchange/changeable-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_f1b4aa1a/pages/train/[id]/exchange/section-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_f1b4aa1a/pages/train/[id]/exchange/train-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/train/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bsX0rST8wDMeta?.name,
    path: "/users/:id()",
    component: () => import("/tmp/build_f1b4aa1a/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_f1b4aa1a/pages/users/[id]/driver-license.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_f1b4aa1a/pages/users/[id]/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_f1b4aa1a/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_f1b4aa1a/pages/users/[id]/passport.vue").then(m => m.default || m)
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_f1b4aa1a/pages/users/[id]/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_f1b4aa1a/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_f1b4aa1a/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_f1b4aa1a/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: component_45stubFTH6VTpzgQMeta?.name,
    path: "/metrics",
    component: component_45stubFTH6VTpzgQ
  },
  {
    name: component_45stubFTH6VTpzgQMeta?.name,
    path: "/en",
    component: component_45stubFTH6VTpzgQ
  },
  {
    name: component_45stubFTH6VTpzgQMeta?.name,
    path: "/fr",
    component: component_45stubFTH6VTpzgQ
  },
  {
    name: component_45stubFTH6VTpzgQMeta?.name,
    path: "/pt-BR",
    component: component_45stubFTH6VTpzgQ
  },
  {
    name: component_45stubFTH6VTpzgQMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubFTH6VTpzgQ
  },
  {
    name: component_45stubFTH6VTpzgQMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubFTH6VTpzgQ
  },
  {
    name: component_45stubFTH6VTpzgQMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubFTH6VTpzgQ
  }
]