import routerOptions0 from "/tmp/build_f1b4aa1a/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/tmp/build_f1b4aa1a/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}